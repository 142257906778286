"use client";

import { ComponentType, FC, ReactNode, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import { withLDProvider } from "launchdarkly-react-client-sdk";

import { StepupListener } from "@components/ExternalContent";
import useAuthStore from "@stores/authStore";
import "@styles/globals.css";
import useCommonInitialization from "@hooks/useCommonInitialization";
import getEnv from "@utils/getEnv";
import "@utils/sentryClient";
// Initialize datadog
import "@services/datadogLog";
import "@services/datadogRum";
import { datadogRum } from "@datadog/browser-rum";
import isInIframe from "@utils/isInIframe";
import { usePathname, useRouter } from "next/navigation";

function GlobalContentWrapper({ children }: { children: ReactNode }) {
  const router = useRouter();
  const pathname = usePathname();
  const user = useAuthStore((state) => state.user);

  const userContext = user && { key: user.uid, email: user.email, firstName: user.firstName, lastName: user.lastName };
  const { theme, appReady, safeDevMode } = useCommonInitialization(userContext ? { user: userContext } : undefined);

  // If we're not in safe dev mode - deny embedded pages not in the iframe
  useEffect(() => {
    if (!safeDevMode && !isInIframe() && (pathname?.includes("/embed/") || pathname?.includes("/example"))) {
      router.replace("/404");
    }
  }, [pathname, router, safeDevMode]);

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StepupListener />
        {appReady ? children : null}
      </ThemeProvider>
    </Box>
  );
}

export default withLDProvider({
  clientSideID: getEnv().LAUNCHDARKLY_CLIENT_SIDE_ID || "",
  timeout: 2,
  options: {
    inspectors: [
      {
        type: "flag-used",
        name: "dd-inspector",
        method: (key, detail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
})(GlobalContentWrapper as unknown as ComponentType) as FC<{ children: ReactNode }>;
